const CheckoutStep = Object.freeze({
  Shipments: 'shipments',
  Allocated: 'manageAllocatedItems',
  PaymentDetails: 'paymentDetails',
  Review: 'reviewOrder',
  Confirmation: 'confirmation',
});

const DeliveryModes = Object.freeze({
  GROUND: 'GROUND',
  TWO_DAY: 'TWO-DAY',
  OVERNIGHT: 'OVERNIGHT',
  BOPIS: 'BOPIS',
  BIKE_TO_HOME: 'BIKE-TO-HOME',
  CLICK_AND_COLLECT: 'CLICK-AND-COLLECT',
  CLICK_AND_COLLECT_NO_BIKE: 'CLICK-AND-COLLECT-NO-BIKE',
  CLICK_AND_COLLECT_NO_BIKE_GROSS: 'CLICK-AND-COLLECT-NO-BIKE_GROSS',
  CLICK_AND_COLLECT_BIKE_OVERRIDE: 'CLICK-AND-COLLECT-BIKE-OVERRIDE',
});

const IconCardTypes = Object.freeze({
  amex: 'amex',
  masterCard: 'masterCard',
  visa: 'visa',
  jcb: 'jcb',
  CYBERSOURCE_APPLE: 'CYBERSOURCE_APPLE',
  GR4VY_APPLE: 'GR4VY_APPLEPAY',
  CYBERSOURCE_IDEAL: 'CYBERSOURCE_IDEAL',
  GR4VY_IDEAL: 'GR4VY_IDEAL',
  electron: 'electron',
  maestro: 'maestro',
});

const CybersourceApplePayPaymentMethodCode = 'CYBERSOURCE_APPLE';

const Gr4vyApplePayPaymentMethodCode = 'GR4VY_APPLEPAY';

const KCPPaymentMethodCode = 'CYBERSOURCE_KCP';

const Gr4vyKCPPaymentMethodCode = 'GR4VY_KCP';

const Gr4vySessionStorageKey = 'GR4VY_SESSION_ID';

export {
  CheckoutStep,
  DeliveryModes,
  IconCardTypes,
  CybersourceApplePayPaymentMethodCode,
  Gr4vyApplePayPaymentMethodCode,
  KCPPaymentMethodCode,
  Gr4vyKCPPaymentMethodCode,
  Gr4vySessionStorageKey,
};
